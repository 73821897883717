import { useEffect, useState } from 'react';
import { fetch } from '../../../../core/infra/dice-admin/FetchService';

interface Adjustment {
    CommodityKey: string;
    Quantity: number;
    Metadata?: {
        [key: string]: string;
    };
}

interface DisplayDetails {
    IsFeaturedItem?: string;
    IsFeatured?: string;
    BannerTextBackgroundColor?: string;
    BackgroundUrl?: string;
    EventWidget?: string;
    PresetPromotionStoreListingConfig?: string;
}

interface StoreListingItem {
    Name: string;
    Debit: {
        Adjustments: Adjustment[];
    };
    Credit: {
        Adjustments: Adjustment[];
    };
    PurchaseLimitPerCustomer: number;
    DisplayDetails: DisplayDetails;
    ExtraCredit?: {};
    IsOriginalReplacement: boolean;
    PurchaseLimitResetFixed: boolean;
    UUID: string;
    HasPerCustomerPurchaseLimit: boolean;
    IsExternal: boolean;
    availableCount?: number;
}

export function hasCreditCommodityPrizeBox(storeListingItem: StoreListingItem) {
    if (!storeListingItem.Credit?.Adjustments?.length) return false;

    const hasPrizeBox = storeListingItem.Credit.Adjustments.some((adjustment) =>
        adjustment.CommodityKey.startsWith('PrizeBox'),
    );

    return hasPrizeBox;
}

export function getCreditCommodityPrizeBoxName(
    storeListingItem: StoreListingItem,
): string | undefined {
    if (!storeListingItem?.Credit?.Adjustments?.length) return undefined;

    const commodityPrizeBox = storeListingItem.Credit.Adjustments.find((commodityData) =>
        commodityData.CommodityKey.startsWith('PrizeBox'),
    );
    if (!commodityPrizeBox) return undefined;

    return commodityPrizeBox.CommodityKey.split('.')[1];
}

async function fetchGetPrizeBoxTypes(prizeBoxName: string) {
    const { data } = await fetch({
        endPoint: `prize-box-types/${prizeBoxName}`,
        method: 'GET',
    });
    return data;
}

// TODO: migrate to react-query
type QueryStatus = 'idle' | 'loading' | 'success' | 'error';

interface LootItem {
    CommodityKey: string;
    Min: number;
    Max: number;
    Chance: number;
}

export interface PrizeBoxType {
    Id: string;
    Name: string;
    Loot: LootItem[];
    PluralName: string;
    Description: string;
}

export function useGetPrizeBoxTypes(prizeBoxName: string) {
    const [data, setData] = useState<PrizeBoxType | undefined>();
    const [error, setError] = useState<unknown | undefined>();
    const [status, setStatus] = useState<QueryStatus>('idle');

    useEffect(() => {
        setStatus('loading');
        fetchGetPrizeBoxTypes(prizeBoxName).then(
            (data) => {
                setData(data?.Data?.PrizeBoxType);
                setStatus('success');
            },
            (error) => {
                setError(error);
                setStatus('error');
            },
        );
    }, [prizeBoxName]);

    return { data, error, status };
}

async function fetchGetCommoditiesByLoopItems(loopItems: LootItem[]) {
    const { data } = await fetch({
        endPoint: 'commodities/web/list',
        method: 'GET',
        params: {
            commodityKeys: loopItems.map((loopItem) => loopItem.CommodityKey).join(','),
        },
    });
    return data;
}

interface Commodity {
    CommodityName: string;
    CommodityKey: string;
    IconImageUrl: string;
    PluralName: string;
}

export type Commodities = Map<Commodity['CommodityKey'], Commodity>;

export function useGetCommoditiesByLoopItems(loopItems: LootItem[] | undefined) {
    const [data, setData] = useState<Commodities | undefined>();
    const [error, setError] = useState<unknown | undefined>();
    const [status, setStatus] = useState<QueryStatus>('idle');

    useEffect(() => {
        if (!loopItems) return;

        setStatus('loading');
        fetchGetCommoditiesByLoopItems(loopItems).then(
            (data) => {
                setData(mapCommoditiesToMap(data?.Data?.Assets));
                setStatus('success');
            },
            (error) => {
                setError(error);
                setStatus('error');
            },
        );
    }, [loopItems]);

    return { data, error, status };
}

function mapCommoditiesToMap(commodities: Commodity[]) {
    const commodityMap: Commodities = new Map();
    commodities.forEach((commodity) => {
        commodityMap.set(commodity.CommodityKey, {
            ...commodity,
        });
    });
    return commodityMap;
}
