import React from 'react';

import { Box } from '@material-ui/core';

import SectionTitle from '../SectionTitle/SectionTitle';
import { useTranslation } from '../../../hooks/useTranslation';

import LatestUpdatesSlider from './LatestUpdatesSlider';
import { useFeatureFlag } from '../../../helpers/featureFlag';

import { standardLatestUpdates } from './data/standardLatestUpdates';
import { FF_CHRISTMAS_BANNER_HOME } from '../../../constants/featureFlag.constants';
import { christmasLatestUpdates } from './data/christmasLatestUpdates';

const HomeLatestUpdates = () => {
    const t = useTranslation();
    const isFFChristmasBannerHomeEnabled = useFeatureFlag(FF_CHRISTMAS_BANNER_HOME);

    const data = isFFChristmasBannerHomeEnabled ? christmasLatestUpdates : standardLatestUpdates;

    return (
        <Box component="section" mt={[5, 6, 6, 8, 10]} pb={[10, 12, 12, 16, 20]}>
            <SectionTitle title={t('welcome')} />
            <LatestUpdatesSlider data={data} />
        </Box>
    );
};

export default HomeLatestUpdates;
