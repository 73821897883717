import { useSelector } from 'react-redux';
import { globalSel } from '../../store/global';

interface GameConfig {
    [key: string]: string;
}

function useFeatureFlag(featureKey: string) {
    const gameConfigData = useSelector<unknown, GameConfig>(globalSel.gameConfigDataSelector);
    const isFeatureEnabled = getFeatureValue(gameConfigData, featureKey);
    return isFeatureEnabled;
}

function getFeatureValue<T extends GameConfig>(data: T, featureKey: keyof T): boolean {
    try {
        return JSON.parse(data[featureKey]);
    } catch (error) {
        return false;
    }
}

export { useFeatureFlag };
